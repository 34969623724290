import * as client_hooks from '../../../src/hooks.client.ts';
import * as universal_hooks from '../../../src/hooks.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76')
];

export const server_loads = [0];

export const dictionary = {
		"/": [19],
		"/auth": [73],
		"/auth/verify-email": [74],
		"/error": [75],
		"/healthcheck/diagnose": [76],
		"/(login)/login": [20,[2]],
		"/(login)/sign-up": [21,[2]],
		"/(login)/sign-up/hp-verify": [22,[2]],
		"/(user)/user-home": [23,[3]],
		"/(user)/user-profile": [24,[3,4]],
		"/(user)/user-profile/account": [25,[3,4]],
		"/(user)/user-profile/appearance": [26,[3,4]],
		"/(user)/user-profile/overview": [27,[3,4]],
		"/(user)/user-profile/profile": [28,[3,4]],
		"/(user)/verify-email": [29,[3]],
		"/[community=slug]": [30,[5],[6]],
		"/[community=slug]/ai-history": [35,[5,8],[6]],
		"/[community=slug]/ai-history/[slug]": [36,[5,8],[6]],
		"/[community=slug]/beta-sandbox": [37,[5,9],[6]],
		"/[community=slug]/(knowledge-base)/category/[slug]/[uuid]": [31,[5,7],[6]],
		"/[community=slug]/changelog": [38,[5,10],[6]],
		"/[community=slug]/changelog/[slug]/[uuid]": [39,[5,10],[6]],
		"/[community=slug]/community/forum/[...rest]": [41,[5],[6]],
		"/[community=slug]/community/[...rest]": [40,[5],[6]],
		"/[community=slug]/content-library": [42,[5,11],[6]],
		"/[community=slug]/context-sources": [43,[5],[6]],
		"/[community=slug]/demo": [44,[5,12],[6]],
		"/[community=slug]/(knowledge-base)/doc": [32,[5,7],[6]],
		"/[community=slug]/(knowledge-base)/doc/[slug]/[uuid]": [33,[5,7],[6]],
		"/[community=slug]/forums": [45,[5,13],[6]],
		"/[community=slug]/forums/community-error": [48,[5,13],[6]],
		"/[community=slug]/forums/home": [49,[5,13],[6]],
		"/[community=slug]/forums/members": [50,[5,13],[6]],
		"/[community=slug]/forums/page/[slug]/[uuid]": [51,[5,13],[6]],
		"/[community=slug]/forums/[forumSlug]/[uuid]": [46,[5,13,14],[6]],
		"/[community=slug]/forums/[forumSlug]/[uuid]/[threadSlug]/[threadUuid]": [47,[5,13,14],[6]],
		"/[community=slug]/home": [52,[5],[6]],
		"/[community=slug]/insights": [53,[5,15],[6]],
		"/[community=slug]/insights/knowledge-base": [~54,[5,15],[6]],
		"/[community=slug]/insights/workspace": [55,[5,15],[6]],
		"/[community=slug]/join": [56,[16]],
		"/[community=slug]/join/[inviteCode]": [57,[16]],
		"/[community=slug]/(knowledge-base)/section/[slug]/[uuid]": [34,[5,7],[6]],
		"/[community=slug]/settings": [58,[5,17],[6]],
		"/[community=slug]/settings/changelog/settings": [59,[5,17],[6]],
		"/[community=slug]/settings/community/custom-roles": [60,[5,17],[6]],
		"/[community=slug]/settings/community/labels": [61,[5,17],[6]],
		"/[community=slug]/settings/community/manage-members": [62,[5,17],[6]],
		"/[community=slug]/settings/community/settings": [63,[5,17],[6]],
		"/[community=slug]/settings/general/overview": [64,[5,17],[6]],
		"/[community=slug]/settings/general/team": [65,[5,17],[6]],
		"/[community=slug]/settings/general/webhooks": [66,[5,17],[6]],
		"/[community=slug]/settings/help-center/content-links": [67,[5,17],[6]],
		"/[community=slug]/settings/help-center/custom-domain": [68,[5,17],[6]],
		"/[community=slug]/settings/help-center/settings": [69,[5,17],[6]],
		"/[community=slug]/settings/widget/settings": [70,[5,17],[6]],
		"/[community=slug]/widget": [71,[18]],
		"/[community=slug]/workspace": [72,[5],[6]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: universal_hooks.reroute || (() => {})
};

export { default as root } from '../root.svelte';